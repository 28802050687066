import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/apps/web/src/components/AppHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/apps/web/src/components/SearchResults.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/code/node_modules/.pnpm/@mui+icons-material@5.14.15_@mui+material@5.14.17_@emotion+react@11.11.1_@types+react@18.2.33_mjsvi6kouoza4zi6m73dxef7fa/node_modules/@mui/icons-material/NotificationsActive.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/node_modules/.pnpm/@mui+material@5.14.17_@emotion+react@11.11.1_@types+react@18.2.33_react@18.2.0__@emotion+styl_zwx34dz5imme3r26ud6x5xuhfi/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/.pnpm/@mui+material@5.14.17_@emotion+react@11.11.1_@types+react@18.2.33_react@18.2.0__@emotion+styl_zwx34dz5imme3r26ud6x5xuhfi/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/.pnpm/@mui+material@5.14.17_@emotion+react@11.11.1_@types+react@18.2.33_react@18.2.0__@emotion+styl_zwx34dz5imme3r26ud6x5xuhfi/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/.pnpm/next@14.2.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
